import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';

import SEO from '../components/SEO';

import Logo from 'assets/svgs/logo.svg';
import AppQRCodeIcon from 'assets/others/app_qr_code.png';
import AppStoreIcon from 'assets/others/app_store.png';
import GooglePlayIcon from 'assets/others/google_play.png';
import SpecialBackgroundImage from 'assets/others/special_background.jpg';
import {
  BREAKPOINTS,
  EXTERNAL_LINKS,
  INTERNAL_LINKS,
  MOBILE_SIDE_PADDING,
  WEB_SIDE_PADDING,
} from 'utils/constants';

export const query = graphql`
  query DownloadPageQuery {
    allPrismicDownloadPage {
      nodes {
        dataRaw
      }
    }
  }
`;

const Container = styled.div({
  display: 'grid',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: `0 ${WEB_SIDE_PADDING}`,
  backgroundImage: `url(${SpecialBackgroundImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  [BREAKPOINTS[800]]: {
    padding: `0 ${MOBILE_SIDE_PADDING}`,
  },
});

const LogoContainer = styled(Link)({
  position: 'absolute',
  top: 32,
  textDecoration: 'none',
});

const StyledLogo = styled(Logo)({
  [BREAKPOINTS[800]]: {
    height: 40,
  },
});

const Title = styled.h1(({ theme }) => ({
  fontSize: 36,
  fontWeight: 700,
  lineHeight: '54px',
  textAlign: 'center',
  color: theme.colors.white,
  margin: 0,
  '+ h1': {
    color: theme.colors.orange,
  },
}));

const ScanAppText = styled.span(({ theme }) => ({
  color: theme.colors.white,
  opacity: 0.8,
  textAlign: 'center',
  fontSize: 12,
  lineHeight: '18px',
  marginBottom: 19,
  ':before': {
    content: '""',
    display: 'block',
    height: 1,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    margin: '15px 0 30px',
  },
}));

const QRCode = styled.img({
  width: 125,
  height: 125,
  justifySelf: 'center',
});

const Divider = styled.div(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: '1fr min-content 1fr',
  gridColumnGap: 9.5,
  alignItems: 'center',
  fontSize: 12,
  lineHeight: '18px',
  color: theme.colors.white,
  marginTop: 18,
  ':before, :after': {
    content: '""',
    display: 'block',
    height: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const Links = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: 29,
  gap: 4,
  padding: '0 4px',
});

const LinkContainer = styled.a({
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
});

const StoreIcon = styled.img({
  width: 148,
  height: '100%',
});

const Download = ({ data }) => {
  const pageData = data.allPrismicDownloadPage.nodes[0].dataRaw;

  return (
    <>
      <SEO
        pageTitle={pageData.page_title && RichText.asText(pageData.page_title)}
        ogTitle={pageData.og_title && RichText.asText(pageData.og_title)}
        twitterTitle={pageData.twitter_title && RichText.asText(pageData.twitter_title)}
        pageDescription={pageData.page_description && RichText.asText(pageData.page_description)}
        ogDescription={pageData.og_description && RichText.asText(pageData.og_description)}
        twitterDescription={
          pageData.twitter_description && RichText.asText(pageData.twitter_description)
        }
        pageImage={pageData?.page_image?.url}
        pageImageAlt={pageData?.page_image?.alt}
        twitterImage={pageData?.twitter_image?.url}
        twitterImageAlt={pageData?.twitter_image?.alt}
        ogImage={pageData?.og_image?.url}
        ogImageAlt={pageData?.og_image?.url?.alt}
      />
      <Container>
        <LogoContainer to={INTERNAL_LINKS.HOME}>
          <StyledLogo />
        </LogoContainer>
        <Title>Reduce stress.</Title>
        <Title>Sleep better.</Title>
        <ScanAppText>Scan with your mobile device to get the app:</ScanAppText>
        <QRCode src={AppQRCodeIcon} />
        <Divider>OR</Divider>
        <Links>
          <LinkContainer href={EXTERNAL_LINKS.APP_STORE} target="_blank">
            <StoreIcon src={AppStoreIcon} />
          </LinkContainer>
          <LinkContainer href={EXTERNAL_LINKS.GOOGLE_PLAY} target="_blank">
            <StoreIcon src={GooglePlayIcon} />
          </LinkContainer>
        </Links>
      </Container>
    </>
  );
};

export default Download;
